import React, { useState } from 'react'
import Layout from '../../../components/layouts'
import Banner from '../../../components/features/Banner'
import SEO from '../../../components/common/SEO';
import ReactWOW from 'react-wow'
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query Whistleblower {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
  const [toggler, setToggler] = useState(false)
  const lightboxProps = {
    toggler: toggler,
    setToggler: setToggler,
    sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
  }

  const bannerProps = {
    title: 'Whistleblower',
    subtitle: `Almost a third of workers in the U.S. have witnessed ethical misconduct, and over half of those witnesses don’t report the incident. Clear communication of what a company considers proper behavior can help reduce the number of incidents, but it is often not enough to avoid ethical issues. Having a whistleblower program in an organization can help reduce the amount of questionable or illegal acts that happen within a company.   `,
    featureName: 'behaviourObservations',
    rightButtonText: 'Why Certificate Tracking Software?',
    rightButtonSrc: 'https://www.youtube.com/watch?v=xE2yXvWB038',
    mockupImg: "/features/whistleblower/whistleblower-main-banner.png"
  }

  const seo = {
    title: 'Workhub | Free Whistleblower Software Ethics Compliance',
    description: 'We offer free Whistleblower software for case management & secure, anonymous reporting on fraud, harassment, misconduct & ethical issues while protecting individuals from retribution or retaliation.',
    image: '/features/whistleblower/social-card.png',
    // pathname,
    // article
  }

  const featureIndex = [
    {
      title: "Promote Speak-up Culture",
      para: "Allow workers to submit policy breaches or wrongdoings within the organization. Breach reports can be linked to a policy to allow insight on which policies have been breached over time.  ",
    },
    {
      title: "Full Privacy & Anonymity",
      para: "Workers can report a breach with full anonymity. All records are fully encrypted in the database – even our developers can’t peek at them. ",
    },
    {
      title: "Assigned Recipients",
      para: "Select the members of your organization to be possible recipients of the Whistleblower reports. Workers can choose from this list when reporting.  ",
    },
    {
      title: "Notifications",
      para: "Recipients are notified via SMS and/or Email that a new breach report has been submitted.",
    },
    {
      title: "Detailed Submission Management",
      para: "Recipients can follow-up on the report with commenting, document upload, detailed log, and status changes. Recipients are the only ones who can view the details. ",
    },
    {
      title: "Reference Number",
      para: "Workers are provided a reference number upon submission. This number can be used for future submissions if the worker has additional comments or repeated occurrences to report. ",
    },
  ]

  return (
    <Layout {...lightboxProps}>
      <SEO {...seo} {...data.site} />
      <Banner {...bannerProps} {...lightboxProps} />

      <main className="feature-main">

        
 
   

      <section className="feature-section">
        <div className="container" >
          <div className="row switch-children">

            <div className="col-lg-6 feature-img">

            <ReactWOW animation="fadeInLeft" delay="0.9s">
              <img 
              className="feature-img-right" 
              src="/features/whistleblower/whistleblower-admin-computer.png"
              alt="Whistleblower Admin Computer View"
               /></ReactWOW>

            </div>


            <div className="col-lg-6 feature-text">
            <h2>It’s an inside job. </h2>
            <p>A whistleblower is a person who exposes information about wrongdoing within a company or organization. Preferably, employees report the issue early, so that it can be dealt with swiftly and make it less likely to occur again. Unfortunately, when an issue is left unaddressed, it can become a greater problem, and can end up being reported to an external body. This can create negative press about the company, potential legal action, lawsuits, and more. A robust whistleblowing program can help reduce the likelihood of these occurrences. </p>
            </div>
          </div>
        </div>
      </section>


      <section className="feature-section">
        <div className="container" >
          <div className="row">

        
            <div className="col-lg-6 feature-text">


              <h2>Importance of having a ‘speak up’ culture. </h2>

              <p>Ineffective responses to workplace wrongdoing can lead to a lack of confidence and a general unhappiness with leadership and management. </p>

              <p>Our whistleblower component is <strong>completely anonymous</strong>; even we can’t see who sent it! This helps prevent retaliation and workplace controversy which can be a major deterrent in reporting. It also helps set the tone that unethical activities or wrongdoing will not be tolerated, and that an organization is committed to taking action. </p>
              
              <p>Ultimately, this is just one step towards having a “speak up” culture at an organization. Issues will have to be addressed accordingly by administration and management for the tool to be effective, but the good news is that it’s a pretty darn big step! </p>

            </div>

            <div className="col-lg-6 feature-img">

            <ReactWOW animation="fadeInRight" delay="0.9s">

              <img 
              className="feature-img-left" 
              src="/features/whistleblower/whistleblower-worker-phone.png"
              alt="Whistleblower Mobile View"
                 />

                </ReactWOW>

            </div>

          </div>
        </div>
      </section>



      <section className="feature-section" style={{padding: '30px 0px 0px 0px'}}>
        <div className="container" >
          <div className="row switch-children">

            <div className="col-lg-6 feature-img">

            <ReactWOW animation="fadeInLeft" delay="0.9s">

              <img 
            className="feature-img-right"
              src="/features/whistleblower/whistleblower-admin-tablet.png"
              alt="Whistleblower Tablet View"
                />

                </ReactWOW>

            </div>


            <div className="col-lg-6 feature-text">

              <h2>Turn it on, then hope it never gets used… </h2>

              <ol className='checklist'>
                <li>Select a list of Possible Recipients. It must be small enough that it is not overwhelming to the worker (we recommend one person, per department), but large enough to avoid conflicts of interest. </li>
                <li>Workers submit whistleblower reports through the worker dashboard. They choose recipient(s) from the list, attach a policy they believe was breached, a location, details about the events, attach files, as well as send a copy to their personal email. Once submitted, a worker can use their uniquely generated reference code to add additional details. </li>
                <li>When a recipient receives a report, they can see the details submitted by the worker, as well as: choose a priority, change the status, attach a related policy or location, attach additional files, or make comments. All details and comments are only viewable by recipients. </li>
                <li>Administrators (who aren’t assigned to a report) are only able to see a restricted amount of information about the submission. They can track the status and priority, related policy, location, and recipients, but all other information is restricted to the report itself. </li>
              </ol>

            </div>
          </div>
        </div>
      </section>

      <section style={{maxWidth: '1500px', margin: 'auto'}}>
      <div className="video-bg-size" style={{width: '45%', margin: 'auto'}}>

<div className="iframe-contain" style={{marginBottom: '30px'}}>
  <iframe width="700" height="500"src="https://www.youtube.com/embed/s0A8q17EV8A" frameborder="0" allowfullscreen></iframe>
</div>

</div>
      </section>

      <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid">
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} />
            ))}
          </div>
        </section>

      </main>

    </Layout>
  )
}